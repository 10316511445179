
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



























































































































































.missions-slidy {
  position: relative;
  padding-bottom: 4rem;

  ::v-deep {
    .slidy-nav {
      @extend %list-nostyle;

      position: absolute;
      top: 2rem;
      width: 100%;

      @include mq(l) {
        top: 0;
        width: col(8);
        margin-top: $spacing * 0.5;
      }
    }

    .slidy-nav__item {
      & + .slidy-nav__item {
        margin-top: 3rem;

        @include mq(l) {
          margin-top: 6rem;
        }
      }

      button {
        @extend %button-nostyle;
        @extend %fw-light;
        // prettier-ignore
        @include fluid(font-size, (xxs: 18px, xxl: 30px));

        color: $c-gray;
        font-size: 1.6rem;
        line-height: 1.25;
        text-align: left;
      }

      &.is-active {
        button {
          color: $c-black;
        }
      }
    }
  }
}

// @FIXME: Define how this component should behave on mobile.
// Until then, hiding visuals to avoid unwanted x overflow
.missions-slidy__list {
  @include mq($until: l) {
    display: none;
  }
}

.missions-slidy__item {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  opacity: 0;

  &:first-child {
    z-index: 2;
    opacity: 1;
  }

  &:nth-child(2n + 1) {
    .missions-slidy__item__picture {
      &:nth-child(2n + 1) {
        transform: translate(-30%, -40%);
      }

      &:nth-child(2n) {
        transform: translate(15%, 40%);
      }
    }
  }

  &:nth-child(2n) {
    .missions-slidy__item__picture {
      &:nth-child(2n + 1) {
        transform: translate(-30%, 70%);
      }

      &:nth-child(2n) {
        transform: translate(15%, -30%);
      }
    }
  }

  @include mq(l) {
    position: absolute;
    right: 0;
    flex-direction: row;
    align-items: center;
    width: col(8);
    margin-left: col(2);
  }
}

.missions-slidy__item__picture-outer {
  position: absolute;
  right: 65%;
}

.missions-slidy__item__picture {
  @include aspect-ratio(4, 3);

  position: absolute;
  flex-shrink: 0;
  width: 35rem;
  height: unset;
  min-height: unset;

  &:nth-child(2n) {
    width: 37rem;
  }
}
