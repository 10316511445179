
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




























.home-missions-section {
  padding-top: $spacing * 4;
}

.home-missions-section__grid {
  // prettier-ignore
  @include fluid(margin-bottom, (l: 25px, xxl: 50px));

  padding-top: $spacing * 1.5;
}
